import Lenis from "lenis";
import React, { useEffect, useRef, useState } from "react";
import About from "./components/About";
import Header from "./components/Header";
import Events from "./components/Events";
import Footer from "./components/Footer";
import imgLogo from "./assets/images/img-logo.png";
import Skills from "./components/Skills";
import Membership from "./components/Membership";
import EarlyAccess from "./components/EarlyAccess";
import Offerings from "./components/Offerings";

function App() {
  const lenisRef = useRef(null);

  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // custom easing function
      direction: "vertical", // can be 'vertical' or 'horizontal'
      smooth: true,
    });

    lenisRef.current = lenis;

    // Scroll animation loop
    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);

    // Clean up
    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <div className="App">
      <div className="header-nav">
        <img src={imgLogo} className="header-logo" alt="logo" />
      </div>

      <Header />

      <Events />
      <Skills />
      <Offerings />
      <Membership />
      <EarlyAccess />
      <Footer />
    </div>
  );
}

export default App;
