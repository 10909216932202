import React, { useRef, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from "framer-motion";
import imgHeaderLogo from "../assets/images/img-logo-bg.png";
import Spline from "@splinetool/react-spline";
import About from "./About";

function Header() {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end end"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const title1Opacity = useTransform(scaleSpring, [0.5, 0.85], ["1", "1"]);
  const title2Opacity = useTransform(scaleSpring, [0.5, 0.55], ["1", "1"]);
  const title1Transform = useTransform(
    scaleSpring,
    [0.25, 0.45],
    ["0px", "0px"]
  );
  const title2Transform = useTransform(
    scaleSpring,
    [0, 1],
    ["250px", "-150px"]
  );

  const [headerInview, setheaderInview] = useState(false);
  const [yPosition, setyPosition] = useState(0);
  const rocks = useRef(null);
  const robo = useRef(null);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [objectsLoaded, setObjectsLoaded] = useState(false);

  function onSplineLoad(spline) {
    const rocksObj = spline.findObjectByName("rocks");
    const roboObj = spline.findObjectByName("robo");

    if (roboObj) {
      console.log("assinging 3d objects = " + rocksObj);
      setSceneLoaded(true);
      robo.current = roboObj;
      rocks.current = rocksObj;
    }
  }

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (sceneLoaded) {
      console.log("3d rocks position = " + rocks.current.position.y);
      if (latest > scrollYProgress.getPrevious()) {
        rocks.current.position.y += 1;
        rocks.current.rotation.y += 0.01;
      } else {
        rocks.current.position.y -= 1;
        rocks.current.rotation.y -= 0.01;
      }
    }
  });

  return (
    <motion.header
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      viewport={{ once: true }}
      className="App-header"
      ref={scrollRef}
    >
      {" "}
      <div className="splineCubeScene">
        <Spline
          scene="https://prod.spline.design/8-P8wPpxftKhACky/scene.splinecode"
          onLoad={onSplineLoad}
        />
      </div>{" "}
      <motion.span className="header-backdrop">
        {" "}
        <div className="header-backdrop-logo">
          <span className="header-backdrop-logo-welcome">Welcome to</span>
          <div
            className="header-backdrop-logo-text glitch"
            data-text="GAME BOSS"
          >
            Game <span className="text-green">Boss</span>
          </div>
          <span className="header-backdrop-logo-img1">
            <img className="header-logo-img" src={imgHeaderLogo}></img>
          </span>
        </div>{" "}
      </motion.span>
      <div className="header-scroll">
        <div className="text">Scroll Down</div>
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <motion.div
        className="header-title header-title-2"
        style={{ opacity: title2Opacity, translateY: title2Transform }}
      >
        <h3 className="header-title-top">UNLock Your Full</h3>
        <h2 className="header-title-bottom">Potential</h2>
      </motion.div>{" "}
      <motion.div
        className="header-title header-title-stroke-1"
        style={{ opacity: title1Opacity, translateY: title1Transform }}
      >
        <h3 className="header-title-top text-stroke">Welcome TO</h3>
        <h2 className="header-title-bottom  text-stroke">GAME BOSS</h2>
      </motion.div>
      <motion.div
        className="header-title header-title-stroke-2"
        style={{ opacity: title2Opacity, translateY: title2Transform }}
      >
        <h3 className="header-title-top text-stroke">UNLock Your Full</h3>
        <h2 className="header-title-bottom  text-stroke">Potential</h2>
      </motion.div>
      <About />
    </motion.header>
  );
}

export default Header;
